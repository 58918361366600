import React, { useEffect } from "react";
import "./FishTank.css";

const Nowruz = () => {
  useEffect(() => {
    const createFlowers = () => {
      const flower = document.createElement("div");
      flower.classList.add("flower");
      flower.textContent = "🌸 "; // نماد گل

      // تنظیم موقعیت و انیمیشن
      const randomLeft = Math.random() * 100; // بین 0 تا 100 درصد
      const randomSize = Math.random() * 2 + 0.5; // اندازه بین 0.5em تا 2.5em
      const randomDuration = Math.random() * 3 + 3; // مدت زمان سقوط 3 تا 6 ثانیه

      flower.style.left = `${randomLeft}vw`;
      flower.style.fontSize = `${randomSize}em`;
      flower.style.animationDuration = `${randomDuration}s`;

      document.querySelector(".flower-container").appendChild(flower);

      // حذف گل پس از اتمام انیمیشن
      setTimeout(() => {
        flower.remove();
      }, randomDuration * 1000);
    };

    // ایجاد گل‌ها به صورت متناوب
    const interval = setInterval(createFlowers, 500);

    return () => clearInterval(interval); // پاک کردن تایمر هنگام حذف کامپوننت
  }, []);

  return (
    <div className="nowruz-container">
      <div className="nowruz-message">نوروز مبارک!</div>
      <div className="flower-container"></div>
    </div>
  );
};

export default Nowruz;
